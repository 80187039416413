import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  }
}));

const SignUpAndBuy = ({ steps, step, onStepIndexChange }) => {
  const classes = useStyles();
  return (
    <div>
      <Stepper activeStep={step} className={classes.stepper}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          if (step.caption) {
            labelProps.optional = <Typography variant="caption">{step.caption}</Typography>;
          }
          return (
            <Step key={step.id} {...stepProps}>
              <StepButton {...labelProps} onClick={() => onStepIndexChange(index)}>{step.label}</StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export default SignUpAndBuy;