import { useReducer, useMemo } from "react";

const reducer = (state, newState) => ({
  ...state,
  ...newState
});

const getInitialState = (metaData, hasPromoCode, hasBasicUserFields) => {
  let initialState = metaData ? Object.fromEntries(metaData.fields.map(x => [x.id, x.type === "checkbox" ? x.defaultChecked : x.defaultValue])) : {};
  if (hasPromoCode)
    initialState.promoCode = "";
  if (hasBasicUserFields)
    initialState = { ...initialState, email: '', firstName: '', lastName: '' };
  return initialState;
}

const useFormMetaData = ({ metaDataJson, hasPromoCode = false, hasBasicUserFields = false, fieldsToExclude = [] }) => {
  const metaData = useMemo(() => {
    if (!metaDataJson)
      return null;
    let result;
    if (Array.isArray(metaDataJson)) {
      for (let json of metaDataJson) {
        // console.log("metaDataJson", JSON.parse(json.replaceAll("'", '"')));
        if (!json)
          continue;
        if (!result)
          result = JSON.parse(json.replaceAll("'", '"'));
        else 
          result.fields = [...result.fields, ...(JSON.parse(json.replaceAll("'", '"')).fields)];
      }
    } else
      result = JSON.parse(metaDataJson.replaceAll("'", '"'));
    if (!result)
      return null;
    // console.log("res0", result, fieldsToExclude);
    result.fields = result.fields.filter(x => !fieldsToExclude.includes(x.id));
    // console.log("res1", result);
    return result;
  }, [metaDataJson, fieldsToExclude]);
  const [state, setState] = useReducer(reducer, getInitialState(metaData, hasPromoCode, hasBasicUserFields));
  const initialErrorState = metaData ? Object.fromEntries(metaData.fields.map(x => [x.id, null])) : {};
  const [errors, setErrors] = useReducer(reducer, initialErrorState);
  const onChange = ({ target: { name, value, type, checked } }) => setState({ [name]: type === "checkbox" ? checked : value });
  return { metaData, state, onChange, errors, setErrors, setState };
}

export default useFormMetaData;