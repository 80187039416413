import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';
import config from "../../uiConfig";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    // float: 'right'
  },
}));

const QuizSuccess = ({passingScore, userScore, onGetCMEClick, goBackLink}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom>Congratulations!</Typography>
      <Typography>The passing score for this course is <b>{passingScore}%</b>.</Typography>
      <Typography>You have passed the test with <b>{userScore}%</b> score.</Typography>
      <br />
      <br />
      <div>
        <Button variant="contained" color="secondary" to={goBackLink} component={Link}>Go Back</Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={onGetCMEClick}>Get {config.certTitle}</Button>
      </div>
    </>
  );
};

QuizSuccess.propTypes = {
  passingScore: PropTypes.number.isRequired,
  userScore: PropTypes.number.isRequired,
  onGetCMEClick: PropTypes.func.isRequired,
  goBackLink: PropTypes.string.isRequired
};

export default QuizSuccess;
