import { useState, useEffect, useRef } from "react";
import api from "../../api";
import usePrevious from "./usePrevious";

const useProductPrice = ({ product, state }) => {
  const [price, setPrice] = useState(0);
  const prevState = usePrevious(state);
  const [isLoading, setLoading] = useState(true);
  const timeoutRef = useRef();

  useEffect(() => {
    // if (state.exhibitHallOnly) {
    //   setPrice(0);
    //   return;
    // }
    if (!product || product?.isFree) {
      setLoading(false);
      return;
    }

    if (prevState) {
      let hasChanges = false;
      for (let reason of (product.pricingReasons || [])) {
        if (state[reason] !== prevState[reason])
          hasChanges = true;
      }
      if (!hasChanges)
        return;
    }

    const updatePrice = async () => {
      setLoading(true);
      const data = {};
      for (let reason of (product.pricingReasons || [])) {
        data[reason] = state[reason];
      }
      console.log({ ...product });
      if (product.id === "recording") {
        const { price, isReasonApplied } = await api.getRecordingPrice({ recordingId: product.recording.id, email: state.email });
        console.log("got recording price", price, isReasonApplied, state);
        setPrice(price);
      } else {
        const { price, isReasonApplied } = await api.getProductPrice(product.id, data);
        console.log("got product price", price, isReasonApplied);
        setPrice(price);
      }
      setLoading(false);
    }
    if (!timeoutRef.current) {
      timeoutRef.current = -1;
      updatePrice();
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updatePrice(), 500);
    return (() => {
      clearTimeout(timeoutRef);
    });
  }, [product, state, prevState]);

  return [price, setPrice, isLoading];
}

export default useProductPrice;
