import React, { useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, alpha } from '@material-ui/core/styles';
import uiConfig from '../uiConfig';
import { inject } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette[uiConfig.main.searchColor].main, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette[uiConfig.main.searchColor].main, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
  },
  inputRoot: {
    color: 'secondary',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const Search = ({ store }) => {
  const [value, setValue] = useState("");
  const classes = useStyles();
  const { searchPlaceholder } = uiConfig;

  const handleSearchChange = e => setValue(e.target.value);

  useEffect(() => {
    store.setSearch(value);
  }, [value])

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={searchPlaceholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearchChange}
        value={value}
      />
    </div>
  );
}

export default inject(({ store }, props) => ({
  store
}))(Search);