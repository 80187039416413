import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

const AlreadyHaveAccountLink = () => {
  const { pathname } = useLocation();
  const classes = useStyles();
  return(
    <Typography variant='body2' className={classes.root}>
      <Link to={pathname + '/login'} component={RouterLink}>Already have an account? Sign In</Link>
    </Typography>
  );
}

export default AlreadyHaveAccountLink;