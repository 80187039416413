import React, { useState, useEffect, useRef } from 'react';
import api from "../api";
import FormControl from '@material-ui/core/FormControl';
import MuiInput from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import OkIcon from '@material-ui/icons/Done';

const PromoCode = ({ setPrice, handleChange, value, eventId }) => {
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [isPriceUpdating, setIsPriceUpdating] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const updatePrice = async () => {
      setIsPriceUpdating(true);
      const { price, isReasonApplied } = await api.getProductPrice(eventId, value ? { "PromoCode": value } : null, value);
      // console.log("got price", price, isReasonApplied);
      setPrice(price);
      setIsPromoCodeApplied(isReasonApplied)
      setIsPriceUpdating(false);
    }
    if (!timeoutRef.current) {
      timeoutRef.current = -1;
      updatePrice();
      return;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => updatePrice(), 500);
    return (() => {
      clearTimeout(timeoutRef.current);
    });
  }, [value, eventId]);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="promoCode">Promo Code</InputLabel>
      <MuiInput
        id="promoCode"
        label="Promo Code"
        name="promoCode"
        value={value}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            {isPriceUpdating ? <CircularProgress size={25} /> : (isPromoCodeApplied && <OkIcon color="primary" />)}
          </InputAdornment>
        }
      />
    </FormControl>
  );
}

export default PromoCode;