import { makeObservable, action, observable, reaction, runInAction, computed } from "mobx";
import api from '../api';
import ChatConnection from "../components/chat/ChatConnection";
import config from '../config';
import uiConfig from '../uiConfig';

const MAX_REFRESH_COUNT = 3;

function checkVersion(portalVersion) {
  const lsRefreshCountPath = `${config.portalName}-refresh-count-${config.version}-${portalVersion}`;
  if (portalVersion > config.version) {
    let refreshCount = parseInt(localStorage.getItem(lsRefreshCountPath));
    if (isNaN(refreshCount))
      refreshCount = 0;
    localStorage.setItem(lsRefreshCountPath, ++refreshCount);
    if (refreshCount < MAX_REFRESH_COUNT)
      location.reload();
  }
}
class AppStore {

  @observable currentUser = null;
  @observable isLoggingIn = false;
  @observable search = "";
  @observable fetchingList = [];
  @observable events = [];
  @observable products = [];
  @observable tracks = [];
  @observable portal = null;
  @observable chatConnection = null;
  @observable chatMessages = [];
  @observable portalName = null;

  constructor() {
    makeObservable(this,);
    reaction(() => this.currentUser, () => this.initChat());
    reaction(() => this.events.length, () => this.initChat());
    reaction(() => this.portalName, () => this.fetchTracks());
    reaction(() => this.currentUser, () => this.fetchTracks());
    // reaction(() => this.fetchingList, () => console.log("this.fetchingList", [...this.fetchingList]));
  }

  async initChat() {
    if (!uiConfig.showChat)
      return;
    if (!this.currentUser || this.events.length === 0)
      return;
    if (!this.events.some(x => x.enableExhibitHall && x.hasExhibitHallAccess))
      return;
    console.log("[STORE]", "initChat called", this.currentUser, this.events);

    let password;
    try {
      password = await api.getChatPassword(this.currentUser.email);
    } catch (error) {
      runInAction(() => this.chatConnection = { state: "Error" });
      return console.error("[STORE]", "get chat password error", this.currentUser.email, error.message || error);
    }

    const connection = new ChatConnection({ username: this.currentUser.email, password, });

    const onNewMessage = (events) => {
      console.log("[CHAT]", "new message events", events);
      for (let event of events) {
        // addMessage(event.message);
        runInAction(() => this.chatMessages = [...this.chatMessages, event.message]);
      }
    }
    const onDeleteMessage = (events) => {
      console.log("[CHAT]", "new delete events", events);
      // setMessages(x => x.filter(x => !events.map(c => c.message_id).includes(x.id)));
      runInAction(() => this.chatMessages = this.chatMessages.filter(x => !events.map(c => c.message_id).includes(x.id)));
    }
    const onUpdateMessageFlags = (events) => {
      console.log("[CHAT]", "new update flags events", events);
      runInAction(() => {
        let newMessages = [...this.chatMessages];
        for (let event of events) {
          // console.log("[CHAT]", "new update flags event", event);
          for (let messageId of event.messages) {
            const index = newMessages.findIndex(x => x.id === messageId);
            if (event.operation === "add") {
              newMessages[index].flags = [...(newMessages.flags || []), event.flag];
              // console.log("message updated", newMessages.flags, newMessages[index], newMessages);
            }
          }
        }
        this.chatMessages = newMessages;
      });
    }
    const messages = await connection.init(null, onNewMessage, onDeleteMessage, onUpdateMessageFlags);
    console.log("[CHAT]", "got messages", messages);
    runInAction(() => this.chatConnection = connection);
    if (!messages)
      return console.error("[CHAT]", "error", "init failed");
    // setMessages(messages);
    runInAction(() => this.chatMessages = messages.filter(x => !x.sender_realm_str));
  }

  @computed
  get unreadMessagesCount() {
    if (!this.chatConnection?.profile)
      return 0;
    // console.log("computed", [...this.chatMessages], this.chatMessages.filter(c => c.sender_id !== this.chatConnection.profile.user_id && !(c.flags || []).includes("read")));
    return this.chatMessages.filter(c => c.sender_id !== this.chatConnection.profile.user_id && !(c.flags || []).includes("read")).length;
  }

  @computed
  get isLoggedIn() {
    return !!this.currentUser;
  }

  @computed
  get isFetching() {
    return this.fetchingList.length > 0;
  }

  @computed
  get recordings() {
    const recordings = this.tracks.map(x => x.recordings || []).flat();
    const uniqueIds = [...new Set(recordings.map(x => x.id))];
    return uniqueIds.map(x => recordings.find(r => r.id === x));
  }

  @action
  setEvents(events) {
    if (JSON.stringify(this.events) !== JSON.stringify(events))
      this.events = events;
  }

  @action
  setPortal(portal) {
    if (JSON.stringify(this.portal) !== JSON.stringify(portal))
      this.portal = portal;
  }

  @action
  setProducts(products) {
    if (JSON.stringify(this.products) !== JSON.stringify(products))
      this.products = products;
  }

  @action
  setTracks(tracks) {
    this.tracks = tracks;
  }

  @action
  setPortalName(portalName) {
    this.portalName = portalName;
  }

  @action
  setUser(userData) {
    this.currentUser = userData;
    this.isLoggingIn = false;
  }

  @action
  setIsLoggingIn(val) {
    this.isLoggingIn = !!val;
  }

  @action
  setSearch(val) {
    this.search = val;
  }

  @action
  setIsFetching(val, name = "none") {
    // console.warn("setIsFetching", val, name);
    const alreadyFetching = val && this.fetchingList.includes(name);
    if (val)
      this.fetchingList = [...this.fetchingList, name]
    else
      this.fetchingList = this.fetchingList.filter(x => x !== name);
    return alreadyFetching;
  }

  fetchPortal = async () => {
    const portal = await api.getPortal();
    checkVersion(portal.version);
    this.setEvents(portal.events);
    this.setProducts(portal.products);
    this.setPortal(portal);
    return portal;
  }

  fetchEvents = async () => {
    const events = await api.getEvents();
    this.setEvents(events);
    return events;
  }

  fetchTracks = async () => {
    console.log("[STORE]", "get tracks", this.portalName, this.currentUser?.id);
    this.setIsFetching(true, "tracks");
    const requestId = Date.now();
    this.lastTracksRequestId = requestId;
    const getFunc = this.currentUser ? api.getTracksLogged.bind(api) : api.getTracks.bind(api);
    try {
      const data = await getFunc(this.portalName);
      if (requestId !== this.lastTracksRequestId)
        return console.log("[STORE]", "ignore tracks result");
      console.log("[STORE]", "got tracks", data.length);
      const tracks = data.map(x => ({ ...x, recordings: x.recordings || [] }));
      this.setTracks(tracks);
    } catch (error) {
      console.error("[STORE]", "get tracks error", error);
    }
    this.setIsFetching(false, "tracks");
  }
}

const configureStore = () => new AppStore();

export default configureStore;
