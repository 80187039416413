import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { inject } from "mobx-react";
import uiConfig from "../../uiConfig";

import api from "../../api";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DownloadIcon from '@material-ui/icons/SaveAlt';
import usePortal from "../hooks/usePortal";
import SupportLink from "../SupportLink";



const CertificatesDialog = ({ isLoggingIn }) => {
  const [isFullyLoaded, setIsFullyLoaded] = useState(true);
  const [certificates, setCertificates] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const history = useHistory();
  //const location = useLocation();
  const { portalName } = usePortal();

  const handleClose = () => {
    history.goBack();
  }

  const handleGetCertificateClick = (recordingId, name) => () => {
    setIsDownloading(true);
    api.getCertificate(recordingId).then((blob) => {
      const url = URL.createObjectURL(blob),
        linkEl = document.createElement("a");
      setIsDownloading(false);
      linkEl.href = url;
      linkEl.download = name;
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    });
  }

  useEffect(() => {
    setIsFullyLoaded(false);
    api.getCertificates(portalName).then((data) => {
      setCertificates(data);
      setIsFullyLoaded(true);
    });
  }, [portalName]);

  return (
    <Dialog open onClose={handleClose} aria-labelledby="account-dialog-title" fullWidth disableScrollLock>
      <DialogTitle id="account-dialog-title">Your {uiConfig.certTitle} certificates</DialogTitle>
      <DialogContent>
        {!isLoggingIn && isFullyLoaded ?
          <>
            {certificates === null || isDownloading ?
              <CircularProgress /> :
              certificates.length === 0 ?
                <Typography>No certificates yet.</Typography> :
                <List
                  dense
                  component="nav"
                  aria-labelledby="certificates-list-subheader"
                >
                  {certificates.map((certificate) => (
                    <ListItem button key={certificate.recordId} onClick={handleGetCertificateClick(certificate.recordId, certificate.name)}>
                      <ListItemIcon>
                        <DownloadIcon />
                      </ListItemIcon>
                      <ListItemText primary={certificate.name} />
                    </ListItem>
                  ))}
                </List>
            }
          </> :
          <CircularProgress />
        }
      </DialogContent>
      <DialogActions>
        <SupportLink />
        <div style={{ width: '100%' }} />
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CertificatesDialog.propTypes = {
  isLoggingIn: PropTypes.bool,
};

export default inject(({ store }, props) => ({
  isLoggingIn: store.isLoggingIn, user: store.currentUser,
}))(CertificatesDialog);
