import React from 'react';

import { makeStyles, alpha } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  item: {
    //backgroundColor: Color(theme.palette.primary.main).alpha(0.1).string(),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, .7),
      color: theme.palette.primary.contrastText,
    }
  }
}));

const TracksList = ({ tracks, handleClick }) => {
  const classes = useStyles();

  return (
    <List component="nav" >
      {tracks.map(track => (
        <ListItem button key={track.id} onClick={handleClick(track.id)} className={classes.item}>
          <ListItemText primary={track.title} />
        </ListItem>
      ))}
    </List>
  );
}

export default TracksList;
