export const portals = [
  {
    year: 2020,
    home: {
      mainText: "",
      // secondaryText: "",
      list: [
        // "24-hours access to the educational content",
        // "Online CE courses",
        // "Available on mobile devices"
      ],
      ctaText: "Sign In",
      ctaTextLoggedIn: "Browse Recordings",
    }
  },
];

export const loungesSchedule = [
]

export const prizeSchedule = [
]

// export const signUpFormData = {
//   size: 'sm', // or md, fullscreen
//   fields: [
//     { id: "phone", label: "Phone Number", type: "phone", mandatory: true, defaultValue: "", xs: 12, sm: 6, md: 6},
//     { id: "accId", label: "Account ID", type: "text", maxLength: 4, mandatory: true, regex: "^\\d*$", defaultValue: "", xs: 12, sm: 6, md: 6, autoComplete: undefined},
//     { id: "allowShare", label: "Share contact data", type: "radio", mandatory: true, options: [{ value: "1", label: "Yes" }, { value: "", label: "No" }], defaultValue: null, row: true, xs: 12, sm: 6, md: 6 },
//     { id: "country", label: "Country", type: "select", mandatory: true, options: [{ value: "1", label: "US" }, { value: "0", label: "Other" }], defaultValue: "1", xs: 12, sm: 6, md: 6 },
//   ]
// }

export const tiers = [
  {
    id: 0,
    title: 'Session',
    price: '60',
    memberPrice: '60',
    description: ['Individual session'],
    buttonText: 'Select',
    buttonVariant: 'outlined',
    summary: "Individual Session"
  },
  // {
  //   id: 1,
  //   title: 'Track',
  //   subheader: 'Most popular',
  //   price: '250',
  //   memberPrice: '195',
  //   description: [
  //     'Four sessions',
  //     'Keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'contained',
  //   summary: "Track (4 sessions)"
  // },
  // {
  //   id: 2,
  //   title: 'Conference',
  //   price: '450',
  //   memberPrice: '395',
  //   description: [
  //     'All sessions and keynote presentation',
  //   ],
  //   buttonText: 'Select',
  //   buttonVariant: 'outlined',
  //   summary: "Entire Conference"
  // },
];

export const sponsors = {
  elite: [
    {
      "url": "http://www.reliablesprinkler.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/8b1d52ed-31a6-4209-beab-266049e868ab/i-6c9070b2-a0de-4c97-aeef-02c859052109.png"
    },
    {
      "url": "https://www.johnsoncontrols.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/72f2d936-20d8-45e6-8bf8-16f8712c5db3/i-3dd4f491-4ff1-4e0c-8edf-a4ff1125eb54.png"
    },
    {
      image: "https://sspblobs.azureedge.net/exhibithalls/9ce63a0e-2c9a-482b-91fb-77764931dbed/i-4f928e11-854a-4ef3-bc88-5ea2f695bbfe.png",
      url: "http://www.victaulic.com/"
    },
  ],
  exclusive: [
    {
      "url": "http://www.agfmfg.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/32c03d4f-f95e-4308-ac19-1179f22cbfe2/i-479cbb7f-75ec-4c28-8d12-ec89f156cfa6.jpg"
    },
    {
      "url": "https://www.lubrizol.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/3158a9d7-88e2-4b99-8339-c05bf7ab1f3f/i-cf0eac83-d153-4466-8fd7-6cf153ee75bd.png"
    },
    {
      "url": "https://www.protectowire.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/2c70e893-9d16-4dad-b12a-ec66a7aea731/i-47ffe290-87b1-44ea-83e5-6fd6aeceb355.jpg"
    },
  ],
  contributing: [
    {
      "url": "http://www.anvilintl.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/119e3734-1c3a-4e22-a4e9-e30dbd9331c8/i-1380442b-d4e5-402d-b9f5-5b8a36823ad0.png"
    },
    {
      "url": "http://www.everlux.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/4d7a0052-9593-4575-8258-8c781e8c7fe4/i-14f85fe3-ca6e-4d54-b099-d5c0efcc9c9b.jpg"
    },
    {
      "url": "https://www.hrssystems.com/?utm_source=sfpe&utm_medium=link&utm_campaign=conference&utm_content=link",
      "image": "https://sspblobs.azureedge.net/exhibithalls/ffb56d5e-a377-4c0e-a74d-5ab218b862a6/i-608e3d59-e4ae-428b-902f-be6f85ac9cb1.png"
    },
    {
      "url": "http://www.keltroncorp.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/78a277d0-9160-40c7-b908-02a5bbd9c5bd/i-f2ece252-09f8-4929-b2cb-71fde0449328.png"
    },
    {
      "url": "http://www.metrafire.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/c7eac00c-8c14-47d6-9036-13c3c2cd7313/i-3465fb59-ec4a-49c9-a9bc-1934d42500b7.png"
    },
    {
      "url": "http://www.stifirestop.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/757c2355-90ce-4293-aad7-98521870f151/i-c864ff3f-7cc8-4e8d-90ef-4cf3d8636164.png"
    },
    {
      "url": "http://www.tornatech.com/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/64804d8a-e3a4-4c87-bc75-3b5dcf52847f/i-c3ba4c43-a728-4462-ae8d-53574390af36.png"
    },
    {
      "url": "https://comtrancorp.com/contact-the-vitalink-team/",
      "image": "https://sspblobs.azureedge.net/exhibithalls/a043fea1-065a-4033-992b-555b9f40c5f0/i-82e86bf3-8ee7-4e9f-a17e-0e405eac3ad7.png"
    },
    {
      image: "https://sspblobs.azureedge.net/exhibithalls/4127c5ff-22f9-4018-b5f6-2fc0a30e3a2a/i-8b2cd97c-e310-47be-b46e-b870255d2d03.png",
      url: "https://www.ecscorrosion.com/"
    },
    {
      image: "https://sspblobs.azureedge.net/exhibithalls/2443e41a-382d-474f-a719-6b1aaf2324a0/i-129ed882-4039-4d7e-a27a-69835816b448.png",
      url: "https://www.sabalcore.com/"
    },
  ],
}

export const eventsContent = [];

export const menu = []
export const individuals = {
  list: [],
  relations: [],
  commercial: [],
  about2018: [],
  about2019: [],
  objectives: [],
  statements: [],
  method: []
}
