import React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import config from '../../config';
import uiConfig from '../../uiConfig';
import { makeStyles } from '@material-ui/core/styles';

import draftToHtml from 'draftjs-to-html';
import { useFeedback } from "../feedback/Service";
import Disclaimer from "../Disclaimer";

const parseSummary = text => {
  try {
    return draftToHtml(JSON.parse(text));
  }
  catch {
    return text;
  }
}

const getSpeakersString = (speakers) => {
  return speakers.map((speaker) => `${speaker.firstName} ${speaker.lastName}`).join(", ");
};

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    // float: 'right'
  },
}));

const QuizCourseReview = ({ recording, onNextClick, isNextLocked }) => {
  const feedback = useFeedback();
  const classes = useStyles();

  const handleShowDisclaimer = () => {
    feedback.alert({ component: (<Disclaimer content={recording.certPackage.disclaimer} />), fullWidth: true, confirmButtomText: "Close", maxWidth: "md", scroll: "body", clickAwayClose: true });
  }

  return (
    <>
      <Typography variant="h6" gutterBottom>Speakers</Typography>
      <Typography>{getSpeakersString(recording.speakers)}</Typography>
      <br />
      <Typography variant="h6" gutterBottom>Summary</Typography>
      <Typography dangerouslySetInnerHTML={{ __html: parseSummary(recording.summary) }} />
      <br />
      {recording.goals &&
        <>
          <Typography variant="h6" gutterBottom>Learning goals and objectives</Typography>
          <Typography>{recording.goals}</Typography>
          <br />
        </>
      }

      <Typography variant="h6" gutterBottom>Credit Information</Typography>
      <Typography>{recording.ceHours} Hours {uiConfig.certTitle} Credit(s)</Typography>
      <br />
      {recording.certPackage && <Button className={classes.button} variant="contained" color="secondary" onClick={handleShowDisclaimer}>{uiConfig.certTitle} Information</Button>}
      <Button variant="contained" color="primary" onClick={onNextClick} disabled={isNextLocked}>{config.questionsBeforeRecording ? "Start Pre-test" : "Next"}</Button>
    </>
  );
};

QuizCourseReview.propTypes = {
  recording: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired,
  isNextLocked: PropTypes.bool
};

export default QuizCourseReview;
