import React, { useState } from 'react';
// import { inject } from "mobx-react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import usePortal from '../hooks/usePortal';
import CloseButton from "../CloseButton";
import { inject } from "mobx-react";
import CombinedBuyForm from '../forms/CombinedBuyForm';
import SupportLink from '../SupportLink';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginBottom: theme.spacing(1),
  },
  submit: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 120,
  },
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  },
  title: {
    paddingBottom: 0,
  },
}));

const getFreeProduct = id => id ? { id, isFree: true } : undefined;

const SignUpDialog = ({ user, open }) => {
  const [size, setSize] = useState("sm");
  const classes = useStyles();
  const history = useHistory();
  const { urlPrefix, uiSettings } = usePortal();
  const { pathname } = useLocation();
  const { freeProductId } = useParams();
  const redirectUrl = pathname === "/sign-up" ? urlPrefix + uiSettings.signInRedirect : pathname.replace("/sign-up", "");
  const backUrl = pathname === "/sign-up" ? urlPrefix : pathname.replace("/sign-up", "");

  const handleClose = success => {
    if (success) {
      history.push(freeProductId ? urlPrefix + uiSettings.signInRedirect : redirectUrl);
      return;
    }
    history.push(freeProductId ? "/" : backUrl);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog open={open} aria-labelledby="buy-dialog-title" fullWidth maxWidth={size} onClose={() => handleClose(false)} fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={() => handleClose(false)} />}
      <DialogTitle id="buy-dialog-title" className={classes.title}>Sign Up</DialogTitle>
      <DialogContent>
        <CombinedBuyForm onSuccess={handleClose} setSize={setSize} product={getFreeProduct(freeProductId)} />
      </DialogContent>
      <DialogActions>
        <SupportLink />
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

export default inject(({ store }, props) => ({
  user: store.currentUser
}))(SignUpDialog);