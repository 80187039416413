import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useAmplitude } from "react-amplitude-hooks";
import { inject } from "mobx-react";
import RestrictedAccessPage from "./RestrictedAccessPage";
import { useHistory } from 'react-router-dom';
import uiConfig from '../../uiConfig';
import EvaluationDialog from '../dialogs/EvaluationDialog';

const useStyles = makeStyles(theme => {
  return ({
    fullWidth: () => ({
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      '& iframe': {
        width: '100vw',
        border: 'none',
        overflowX: 'hidden',
        overflowY: "auto",
      }
    }),
    content: {
      whiteSpace: 'break-spaces'
    },
    header: {
      textAlign: 'center',
      marginTop: -30,
      '& img': {
        height: 90,
      }
    }
  })
});

// const formatName = ({ firstName, lastName }) => (firstName ? firstName + " " : "") + (lastName || "");

const EventLive = ({ event, user, store }) => {
  const [endedSessionId, setEndedSessionId] = useState(null);
  const classes = useStyles();
  // const { logEvent } = useAmplitude({ page: event.alias, email: user?.email });
  const history = useHistory();

  useEffect(() => {
    if (event.hasAccess) return;
    // if (!event.canRegister) return;
    history.push("/event/" + event.alias);
  }, [event.hasAccess]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;";
    return () => {
      document.getElementsByTagName("body")[0].style = "overflow: auto;";
    }
  }, []);

  useEffect(() => {
    const receiveMessage = ({ data }) => {
      if (data.type !== "endedSessionId")
        return;
      const { value: endedSessionId } = data;
      console.log("Received event ", endedSessionId);
      if (endedSessionId)
        setEndedSessionId(endedSessionId);
    }
    window.addEventListener("message", receiveMessage, true);
  }, []);

  // useEffect(() => {
  //   setTimeout(() => setEndedSessionId("99f76968-e4f8-40e5-b5fa-1a11bed33a5f"), 5000);
  // }, []);

  const iframeQuery = user ? `?chatEmail=${user.email}&email=${user.email}&showSchedule=1` : "";
  if (!event.hasAccess && !event.canRegister) {
    return (<RestrictedAccessPage />);
  }
  let { alias } = event;
  console.log({ endedSessionId });
  return (
    <>
      {(uiConfig.liveSessionEvaluation && endedSessionId) &&
        <EvaluationDialog
          event={event}
          endedSessionId={endedSessionId}
          entity="session"
        />
      }
      <div className={classes.fullWidth}>
        <iframe src={`https://slidespiel.com/${alias}/live/embed${iframeQuery}`} allowFullScreen style={{ height: `calc(100vh - ${uiConfig.main.childrenMarginTop + 5}px)`, minHeight: 400 }} /* scrolling="no" */ />
      </div>
    </>
  );
}

export default inject("store")(EventLive);

