import React from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';
import config from '../../config';
import Video from './Video';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const getSpeakersString = (speakers) => speakers.map((speaker) => `${speaker.firstName} ${speaker.lastName}`).join(", ");

const getTracksString = (tracks) => tracks.map((track) => track.title).join(", ");

const QuizVideoEmbed = ({ recording, onNextClick, isNextLocked, onRetryClick, attemptsLeft }) => {
  const getSrc = (alias) => {
    return `https://slidespiel.com/view/${alias}`;
  };

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">Speakers: {getSpeakersString(recording.speakers)}</Typography>
        <Typography variant="subtitle1">Track: {getTracksString(recording.tracks)}</Typography>
        <Typography variant="subtitle1">Attempts Left: {attemptsLeft}</Typography>
        <br />
        <Button variant="contained" color="secondary" className={classes.button} onClick={onRetryClick} disabled={isNextLocked}>Back</Button>
        <Button variant="contained" color="primary" onClick={onNextClick} disabled={isNextLocked}>Start {config.questionsBeforeRecording ? "Post-test" : "Test"}</Button>
        <br />
        <br />
      </Grid>
      <Grid item xs={12} md={6}>
        <Video src={getSrc(recording.alias)} />
      </Grid>
      <div>
      </div>
    </Grid>
  );
};

QuizVideoEmbed.propTypes = {
  recording: PropTypes.object.isRequired,
  onNextClick: PropTypes.func.isRequired,
  isNextLocked: PropTypes.bool
};

export default QuizVideoEmbed;
