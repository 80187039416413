import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const QuizQuestionsStepper = ({ step, stepsCount }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const items = new Array(stepsCount).fill(1);
  console.log(step, stepsCount, items)
  return(
    <Stepper orientation={isSm ? "vertical" : "horizontal"} alternativeLabel={!isSm}>
      {items.map((item, index) => {
        //console.log(step - 1, index);
        if (item === -1) return null;
        return (
          <Step key={index} active={index === step - 1} completed={index < step - 1}>
            <StepLabel>Question {index + 1}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default QuizQuestionsStepper;