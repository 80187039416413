import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import api from "../../api";
import { inject } from "mobx-react";
import { useFeedback } from '../feedback/Service';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

const UserProductsDialog = ({ userId, open, handleClose, allProducts, eventsOnly = false }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [sendEmail, setSendEmail] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const feedback = useFeedback();

  const getUserProducts = useCallback(() => {
    if (!userId) return;
    setIsFetching(true);
    api.getUserProducts(userId).then(data => {
      setProducts(data.map(x => ({ ...x, id: x.productId })));
      console.log("products fetch result", data);
      setIsFetching(false);
    }).catch(error => {
      console.warn("Error getting user products", error.response?.data?.message || error);
      setIsFetching(false);
    });
  }, [userId]);

  const handleAdd = async () => {
    if (selectedProduct === 0) return;
    try {
      setIsFetching(true);
      await api.addUserProduct(userId, { sendEmail, productId: selectedProduct });
      getUserProducts();
    }
    catch (error) {
      console.error("Error adding product", error.response?.data?.message || error)
      setIsFetching(false);
      feedback.snackbar({ text: error.response?.data?.message || "Error adding product", type: "error" });
      return;
    }
    setSendEmail(false);
    setSelectedProduct(0);
    feedback.snackbar({ text: "Access granted", type: "success" });
  }

  const handleDelete = productId => async () => {
    if (!await feedback.confirm({ title: "Remove access to this product?" }))
      return;
    try {
      setIsFetching(true);
      await api.removeUserProduct(productId, userId);
      getUserProducts();
    }
    catch (error) {
      console.error("Error removing access", error.response?.data?.message || error)
      setIsFetching(false);
      feedback.snackbar({ text: error.response?.data?.message || "Error removing product", type: "error" });
      return;
    }
    feedback.snackbar({ text: "Access removed", type: "success" });
  }

  useEffect(() => {
    getUserProducts();
  }, [getUserProducts]);

  let availableProducts = allProducts.filter(x => !products.map(c => c.id).includes(x.id));
  if (eventsOnly)
    availableProducts = availableProducts.filter(x => x.events?.length > 0);
  console.log([...availableProducts.map(x => ({ ...x }))]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableScrollLock
    >
      {isFetching ? <LinearProgress variant="query" /> : <div style={{ height: 4 }}></div>}
      <DialogTitle id="alert-dialog-title">{eventsOnly ? "User's Events" : "User's Products"}</DialogTitle>
      <DialogContent>
        {availableProducts.length > 0 &&
          <>
            <Select
              style={{ minWidth: 150 }}
              labelId="event-label"
              id="event-select"
              value={selectedProduct}
              onChange={e => setSelectedProduct(e.target.value)}
            >
              <MenuItem value={0}>Select product</MenuItem>
              {availableProducts.map(product => (<MenuItem value={product.id} key={product.id}>{product.title}</MenuItem>))}
            </Select>
            <FormControlLabel
              style={{ marginLeft: 5 }}
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={e => setSendEmail(e.target.checked)}
                  color="primary"
                />
              }
              label={"Send Email"}
            />
            <Button color="primary" disabled={selectedProduct === 0} onClick={handleAdd}>Add</Button>
            <br />
            <br />
          </>
        }
        {products.length > 0 ?
          <List component="nav">
            {products.map((product, i) => (
              <ListItem key={product.id}>
                <ListItemText primary={product.title} secondary={dayjs(product.purchaseDateUtc).format("LLL") + " UTC"} />
                <IconButton onClick={handleDelete(product.id)}>
                  <RemoveIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          :
          <DialogContentText>
            No {eventsOnly ? "events" : "products"} available for this user.
          </DialogContentText>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default inject(({ store }, props) => ({
  allProducts: store.products,
}))(UserProductsDialog);