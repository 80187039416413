import config from "./config";
import axios from 'axios';

const portalId = config.portals.find(x => x.year === config.defaultYear).id;
const defaultPortalName = config.portalName + "-" + config.defaultYear;

const api = {
  getUser(id = "me", accessToken, portalName) {
    return this.req("get", `user/${id}`, null, accessToken, portalName);
  },

  login(username, password, id = portalId) {
    const data = new URLSearchParams();
    data.append("portalId", id);
    data.append("grant_type", "password");
    data.append("username", username);
    data.append("password", password);
    const url = `${config["apiHost"]}/token`;
    return axios({
      url,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then(({ data }) => data).catch(error => {
      if (error.code === 'ERR_NETWORK') {
        error.response.data = { message: "Network error! Please check your internet connection." };
      }
      throw error;
    });
  },

  logOut(portalName) {
    console.log("newApi", "removing token");
    localStorage.removeItem(`${portalName}-access_token`);
  },

  register(data) {
    return this.req("post", "v3/user/register", data);
  },

  checkUser({ email, portalId }) {
    return this.req("get", `user/check?portalId=${portalId}&email=${email}`);
  },

  buyProduct(data) {
    return this.req("post", "v2/user/buyproduct", data);
  },

  getProductPrice(entityId, data) {
    const url = `v4/price/${entityId}`;
    return this.req("post", url, data);
  },

  getRecordingPrice({ recordingId, email }) {
    const url = `price/recording?recordingId=${recordingId}&email=${email}`;
    return this.req("get", url);
  },

  sendPasswordResetLink(email, portalId) {
    const data = {
      portalId,
      email,
    }
    return this.req("post", "user/sendPasswordResetLink", data);
  },

  resetPassword(email, password, code) {
    const data = {
      password,
      email,
      code,
    }
    return this.req("post", "user/resetPassword", data);
  },

  getSchedule(eventId) {
    return this.speakerReq(`event/${eventId}/schedule`);
  },

  getRooms(eventId) {
    return this.speakerReq(`event/${eventId}/rooms`);
  },

  speakerReq(path, method = 'get', data, axiosOpts = {}) {
    const url = `${config.spmApiHost}/api/${path}`;
    return fetch(url).then(response => response.json());
  },

  getLogins() {
    return this.req("get", "analytics/logins");
  },

  getLoginsCsv() {
    return this.reqBin(`analytics/logins/csv`);
  },

  getSignUpsCsv(id) {
    return this.reqBin(`analytics/signupdata/${id}`);
  },

  getEventConnectionTrackerDataCsv(eventId) {
    return this.reqBin(`analytics/event/${eventId}/connectionTracking`);
  },

  getEventAttentionTrackerDataCsv(eventId) {
    return this.reqBin(`analytics/event/${eventId}/attentionTracking`);
  },

  getPlaybackTrackerDataCsv() {
    return this.reqBin(`analytics/portal/${portalId}/playbackTracking`);
  },

  getSessionEvaluationDataCsv(id) {
    return this.reqBin(`analytics/evaluation/${id}`);
  },

  getSales(days = 1) {
    return this.req("get", "analytics/sales?days=" + days);
  },

  getSalesCsv() {
    return this.reqBin(`analytics/sales/csv`);
  },

  getLastCertificates() {
    return this.req("get", "analytics/certificates");
  },

  getLastCertificatesCsv() {
    return this.reqBin(`analytics/certificates/csv`);
  },

  getMembers(searchString, portalName) {
    return this.req("get", "members?value=" + searchString, null, null, portalName);
  },

  addMember(email, portalName) {
    return this.req("post", "member?email=" + email, null, null, portalName);
  },

  deleteMember(email, portalName) {
    return this.req("delete", "member?email=" + email, null, null, portalName);
  },

  getUsers(searchString, portalId, portalName) {
    return this.req("get", `portal/${portalId}/users?value=${searchString}`, null, null, portalName);
  },

  addUser(data, portalName) {
    return this.req("post", "portal/users", data, null, portalName);
  },

  updateUser(data, portalName) {
    return this.req("post", "portal/users/usersmanagement/access", data, null, portalName);
  },

  deleteUser(userId, portalName) {
    return this.req("delete", "portal/users/" + userId, null, null, portalName);
  },

  changePassword(data, portalName) {
    return this.req("post", "user/changepassword", data, null, portalName);
  },

  buyRecording(recordingId, token, portalName) {
    return this.req("post", "user/buyrecording", { recordingId, token }, null, portalName);
  },

  getEvents() {
    return this.req("get", `portal/${portalId}/events`);
  },

  getPortal() {
    return this.req("get", `portal/${portalId}`);
  },

  getExhibitors(eventId) {
    return this.req("get", `v2/event/${eventId}/exhibithall`);
  },

  getExhibitor(exhibitorId) {
    return this.req("get", `exhibit/${exhibitorId}`);
  },

  getExhibitorFile(exhibitorId, fileId, onProgress) {
    return this.reqBin(`exhibit/${exhibitorId}/file/${fileId}`, undefined, onProgress);
  },

  getChatPassword(email) {
    return this.req("post", "chat1/user", { email });
  },

  uploadChatFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.newReqPortal("post", "chat1/file", formData);
  },

  getSessionForEvaluation(eventId) {
    return this.req("get", `event/${eventId}/sessionsevaluate/test`);
  },

  evaluateSession(data) {
    return this.req("post", "sessionevaluation", data);
  },

  askSpeaker(recordingId, question) {
    return this.req("post", `speakerquestion/recording/${recordingId}`, { question });
  },

  getUserEvents(userId) {
    return this.req("get", `user/${userId}/events`);
  },

  addUserToEvent(userId, data) {
    return this.req("post", `user/${userId}/events`, data);
  },

  removeUserFromEvent(eventId, userId) {
    return this.req("delete", `user/${userId}/events/${eventId}`);
  },

  getUserProducts(userId) {
    return this.req("get", `user/${userId}/products`);
  },

  addUserProduct(userId, data) {
    return this.req("post", `user/${userId}/products`, data);
  },

  removeUserProduct(productId, userId) {
    return this.req("delete", `user/${userId}/products/${productId}`);
  },

  getUserRecordings(userId) {
    return this.req("get", `user/${userId}/recordings`);
  },

  addUserRecording(userId, data) {
    return this.req("post", `user/${userId}/recordings`, data);
  },

  removeUserRecording(recordingId, userId) {
    return this.req("delete", `user/${userId}/recordings/${recordingId}`);
  },

  getTracks() {
    return this.req("get", `portal/${portalId}/preview`);
  },

  getTracksLogged(portalName, accessToken = null) {
    return this.req("get", "portal/content", null, accessToken, portalName);
  },

  getCertificates(portalName) {
    return this.req("get", "user/certificates", null, null, portalName);
  },

  getCertificate(recordingId) {
    return this.reqBin(`user/certificate/${recordingId}`);
  },
  callMeLater(exhibitorId) {
    return this.req("post", `exhibit/${exhibitorId}/callmelater`);
  },
  callMeNow(exhibitorId, representativeId, data) {
    return this.req("post", `exhibit/${exhibitorId}/${representativeId}/callmenow`, data);
  },
  trackChat(exhibitorId, representativeId) {
    return this.req("post", `exhibit/${exhibitorId}/${representativeId}/chat`);
  },
  requestContent(portalName, data) {
    return this.req("post", "exhibit/request-content", data, null, portalName);
  },

  validateUsersCsv(file) {
    const formData = new FormData();
    formData.append("csv", file);
    return this.reqUpload("post", "portal/users/csv/validate", formData);
  },

  uploadUsersCsv(file, products, sendEventEmail) {
    const formData = new FormData();
    formData.append("csv", file);
    return this.reqUpload("post", `portal/users/csv?products=${products}&sendEventEmail=${sendEventEmail}`, formData);
  },

  validateMembersCsv(file) {
    const formData = new FormData();
    formData.append("csv", file);
    return this.reqUpload("post", "members/csv/validate", formData);
  },

  uploadMembersCsv(file) {
    const formData = new FormData();
    formData.append("csv", file);
    return this.reqUpload("post", `members/csv`, formData);
  },

  reqUpload(method, path, data, onUploadProgress = () => { }) {
    return this.req(method, path, data, null, undefined, {
      mimeType: "multipart/form-data",
      contentType: false,
      processData: false,
      dataType: "json",
      onUploadProgress
    });
  },

  reqBin(path, accessToken, onProgress = () => { }) {
    return this.req("GET", path, null, accessToken, undefined, { onDownloadProgress: onProgress, responseType: "blob" });
  },

  req(method, path, data, accessToken, portalName = defaultPortalName, axiosOpts) {
    const url = `${config["apiHost"]}/api/${path}`;
    const token = accessToken || localStorage.getItem(`${portalName}-access_token`);
    return axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...axiosOpts
    }).then(({ data }) => data);
  },

  getRecordingQuiz(recordingId, portalName) {
    return this.req("get", `testing/${recordingId}`, null, null, portalName);
  },

  setQuizReset(recordingId, portalName) {
    return this.req("post", `testing/${recordingId}/proceed/1`, null, null, portalName);
  },

  setRecordingQuizStep(recordingId, portalName) {
    return this.req("post", `testing/${recordingId}/next`, null, null, portalName);
  },

  setRecordingQuizFeedback(recordingId, feedback, portalName) {
    return this.req("post", `testing/${recordingId}/next`, { feedback }, null, portalName);
  },

  postRecordingQuizAnswer(recordingId, answerId, portalName) {
    return this.req("post", `testing/${recordingId}/answer/${answerId}`, null, null, portalName);
  },
};

export default api;
