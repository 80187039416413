import React, { useState/* , useEffect, useCallback */ } from 'react';
import { inject } from "mobx-react";
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// import UserEventsDialog from "../dialogs/UserEventsDialog";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import dayjs from 'dayjs';
import { useFeedback } from '../feedback/Service';
import api from "../../api";
import usePortal from '../hooks/usePortal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import useUploadCsv from '../hooks/useUploadCsv';
import UserProductsDialog from '../dialogs/UserProductsDialog';
import UserRecordingsDialog from '../dialogs/UserRecordingsDialog';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

const getLoginTime = user => {
  if (!user.lastLoginTime) return "no data";
  return dayjs(user.lastLoginTime).format("LLL") + " UTC";
}

const csvCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "password", title: "Password" },
];

const Users = ({ store, products, isFetching, currentUser }) => {
  const [userProducts, setUserProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [eventsDialogUserId, setEventsDialogUserId] = useState(null);
  const [productsDialogUserId, setProductsDialogUserId] = useState(null);
  const [recordingsDialogUserId, setRecordingsDialogUserId] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);
  const [tab, setTab] = useState(0);

  const { portalId, portalName, /* urlPrefix */ } = usePortal();

  const { isUploading, upload: handleUploadUsers } = useUploadCsv({
    validateFunc: api.validateUsersCsv.bind(api),
    uploadFunc: api.uploadUsersCsv.bind(api),
    csvCols,
    uploadFuncParams: [userProducts.join(","), sendEmail],
  });

  const feedback = useFeedback();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length > 2)
      fetch(value);
  }

  const fetch = searchString => {
    store.setIsFetching(true);
    api.getUsers(searchString, portalId, portalName).then(data => {
      console.log(data);
      setUsers(data);
      store.setIsFetching(false);
    });
  }

  const handleDelete = ({ id, email }) => async e => {
    if (!await feedback.confirm({ title: "Remove user?", text: `Are you sure you want to delete ${email} from users?` }))
      return;
    api.deleteUser(id, portalName).then(x => {
      fetch(search);
      feedback.snackbar({ text: 'User has been successfully deleted.', type: "success" });
    }).catch(x => {
      feedback.snackbar({ text: x.response?.data?.message || "Error deleting user", type: "error" });
    });
  }

  const handleChangePassword = email => async () => {
    const password = await feedback.form({ title: "Change Password", input: { title: 'New password', type: "password", name: "password" } });
    if (!password)
      return;
    store.setIsFetching(true);
    api.changePassword({ email, password }).then(() => {
      feedback.snackbar({ text: "Password changed.", type: "success" });
      store.setIsFetching(false);
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.response?.data?.message || "Error changing password", type: "error" });
    });
  }

  const handleToggleAdmin = user => async () => {
    store.setIsFetching(true);
    api.updateUser({ ...user, hasAccessToUserManagement: !user.hasAccessToUserManagement }).then(() => {
      feedback.snackbar({ text: "User updated", type: "success" });
      store.setIsFetching(false);
      fetch(search);
    }).catch(x => {
      console.log(x);
      store.setIsFetching(false);
      feedback.snackbar({ text: x.response?.data?.message || "Error updating user", type: "error" });
    });
  }

  const handleOpenEvents = id => () => setEventsDialogUserId(id);
  const handleOpenProducts = id => () => setProductsDialogUserId(id);
  const handleOpenRecordings = id => () => setRecordingsDialogUserId(id);

  const handleSubmit = async e => {
    e.preventDefault();
    store.setIsFetching(true);
    try {
      const user = await api.addUser({ email, firstName, lastName, password, hasAccessToUserManagement: isAdmin, sendEventEmail: sendEmail, products: userProducts }, portalName);
      console.log(user);
      setEmail('');
      setFirstName('');
      setLastName('');
      setPassword('');
      // setDefaultProduct();
      // setUserProducts([]);
      store.setIsFetching(false);
      feedback.snackbar({ text: "User has been successfully added.", type: "success" });
    } catch (error) {
      console.log(error);
      store.setIsFetching(false);
      feedback.snackbar({ text: error.response?.data?.message || "Error adding user", type: "error" });
    }
  }

  // const setDefaultProduct = useCallback(() => {
  //   if (userProducts.length > 0)
  //     return;
  //   if (products?.length < 1) {
  //     setUserProducts([]);
  //     return;
  //   }
  //   if (products.length > 0)
  //     setUserProducts([products[0].id]);
  //   else
  //     setUserProducts([]);
  // }, [products]);

  // useEffect(() => {
  //   setDefaultProduct();
  // }, [setDefaultProduct]);

  return (
    <>
      <Typography variant="h6" gutterBottom>Users</Typography>
      {/* <UserEventsDialog open={!!eventsDialogUserId} userId={eventsDialogUserId} handleClose={() => setEventsDialogUserId(null)} /> */}
      <UserProductsDialog open={!!eventsDialogUserId} userId={eventsDialogUserId} handleClose={() => setEventsDialogUserId(null)} eventsOnly />
      <UserProductsDialog open={!!productsDialogUserId} userId={productsDialogUserId} handleClose={() => setProductsDialogUserId(null)} />
      <UserRecordingsDialog open={!!recordingsDialogUserId} userId={recordingsDialogUserId} handleClose={() => setRecordingsDialogUserId(null)} />
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, x) => setTab(x)}
      >
        <Tab label="Add User" />
        {currentUser.isSuperAdmin && <Tab label="Upload users list" />}
      </Tabs>
      <Paper style={{ padding: 16 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {tab === 0 &&
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="text"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    placeholder="First Name"
                    // margin="dense"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="text"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    placeholder="Last Name"
                    // margin="dense"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Email"
                    // margin="dense"
                    size="small"
                    fullWidth
                    variant="outlined"
                    required
                    autoComplete="new-email"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Password"
                    // margin="dense"
                    size="small"
                    fullWidth
                    variant="outlined"
                    // required
                    autoComplete="new-password"
                  />
                </Grid>
              </>
            }
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="products-label">Products</InputLabel>
                <Select
                  style={{ minWidth: 150 }}
                  labelId="products-label"
                  id="products-select"
                  value={userProducts}
                  onChange={e => setUserProducts(e.target.value)}
                  multiple
                  renderValue={selected => selected.map(x => products.find(p => p.id === x).title).join(', ')}
                  fullWidth
                >
                  {/* <MenuItem value={0}>Select event</MenuItem> */}
                  {products.map(product => (
                    <MenuItem value={product.id} key={product.id}>
                      <Checkbox checked={userProducts.includes(product.id)} />
                      <ListItemText primary={product.title} />
                    </MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            {/* {userEvents.length > 0 && */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmail}
                    onChange={e => setSendEmail(e.target.checked)}
                    color="primary"
                  />
                }
                label={"Send Email"}
              />
            </Grid>
            {/* } */}
            {tab === 0 &&
              <Grid item xs={12} sm={6} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAdmin}
                      onChange={e => setIsAdmin(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={"Admin"}
                />
              </Grid>
            }
            <Grid item xs={12}>
              {tab === 0 && <Button variant="contained" color="primary" type="submit" disabled={isFetching}>Create User</Button>}
              {tab === 1 &&
                <>
                  <Typography gutterBottom variant='caption'>CSV Columns: FirstName, LastName, Email, Password</Typography>
                  <br />
                  <input
                    style={{ display: 'none' }}
                    id="upload-users"
                    type="file"
                    accept=".csv"
                    onChange={handleUploadUsers}
                    disabled={isUploading}
                  />
                  <label htmlFor="upload-users">
                    <Button component="span" variant="contained" color="default" disabled={isUploading}>Upload CSV</Button>
                  </label>
                </>
              }
            </Grid>
          </Grid>
        </form>
      </Paper>
      <br />
      <TextField
        fullWidth
        type="search"
        value={search}
        onChange={handleChange}
        name="search"
        placeholder="Search users by email..."
        margin="dense"
        size="small"
        variant="outlined"
      />
      <List component="nav" aria-labelledby="certificates-list-subheader"      >
        {users.map((user, i) => (
          <ListItem key={i}>
            <ListItemText primary={user.email} secondary={user.firstName + " " + user.lastName + ", Last login: " + getLoginTime(user) + (user.hasAccessToUserManagement ? ", Administrator" : "")} />
            <Button color="primary" onClick={handleChangePassword(user.email)}>Change Password</Button>
            <Button color="primary" onClick={handleOpenProducts(user.id)}>Products</Button>
            <Button color="primary" onClick={handleOpenRecordings(user.id)}>Recordings</Button>
            <Button color="primary" onClick={handleOpenEvents(user.id)}>Events</Button>
            <Button color="primary" onClick={handleToggleAdmin(user)}>{user.hasAccessToUserManagement ? "Revoke Admin" : "Grant Admin"}</Button>
            <IconButton onClick={handleDelete(user)}>
              <RemoveIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default inject(({ store }, props) => ({
  isFetching: store.isFetching, store, currentUser: store.currentUser, portal: store.portal, search: store.search, products: store.products,
}))(Users);