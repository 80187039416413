import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(1),
    position: 'relative',
    width: "100%",
  },
  button: {
    //margin: theme.spacing(2, 0, 2),
    minWidth: 120,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LoadingButton = ({ loading, children, disabled, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.button}
        disabled={loading || disabled}
        {...rest}
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
}

export default LoadingButton;