import React/*,  { useState, useEffect }  */from "react";
import { sponsors } from '../data';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10
  },
  image: {
    maxWidth: 180,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
}));

const Sponsor = ({ item }) => {
  // const [image, setImage] = useState("");
  const classes = useStyles();
  // useEffect(() => {
  //   if (image)
  //     return;
  //   const loadBackground = async () => {
  //     const { default: url } = await import('../assets/images/sponsors/' + item.image);
  //     //console.log(url);
  //     setImage(url);
  //   }
  //   loadBackground();
  // }, [item, image]);
  // if (!image) return null;
  return (
    <a href={item.url} target="_blank" rel="noreferrer noopener">
      <img src={item.image} className={classes.image} />
    </a>
  );
}

const Sponsors = ({ level }) => {
  const classes = useStyles();
  const data = level ? sponsors[level] : sponsors;
  return (
    <div className={classes.root}>
      <Grid container spacing={2} justify="space-evenly" alignItems="center">
        {data.map(item => (
          <Grid key={item.url} item xs={6} sm={4} md={3} lg={3}>
            <Sponsor item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Sponsors;