import React from 'react';
import { inject } from "mobx-react";
import SecuredRoute from './SecuredRoute';
import EventLive from './pages/EventLive';
import EventHome from './pages/EventHome';
import ExhibitHall from "./pages/ExhibitHall";
import Exhibitor from "./pages/Exhibitor";
import NetworkingRoom from "./pages/NetworkingRoom";
import EvaluationDialog from "./dialogs/EvaluationDialog";
import { useHistory } from 'react-router-dom';
import EventCustomPage from "./pages/EventCustomPage";
import SponsorsPage from "./pages/SponsorsPage";

const Events = ({ events, user, store, portal, products }) => {
  const history = useHistory();

  const getCanRegister = id => products.filter(x => (x.events || []).includes(id)).length > 0;

  return (
    <>
      {events.map(event =>
        <React.Fragment key={event.id}>
          <SecuredRoute exact path={`/event/${event.alias}/:register(register)?`} secured={!getCanRegister(event.id)}><EventHome event={event} /></SecuredRoute>
          <SecuredRoute path={"/event/" + event.alias + "/live"} secured={true}><EventLive event={event} user={user} /></SecuredRoute>
          <SecuredRoute path={"/event/" + event.alias + "/sponsors"} secured={false}><SponsorsPage sponsors={event.sponsorship} /></SecuredRoute>
          {event.evaluationFormMetaData &&
            <SecuredRoute path={"/event/" + event.alias + "/live/session-evaluation"} secured={true}>
              <EvaluationDialog event={event} handleClose={() => history.goBack()} entity="session" />
            </SecuredRoute>
          }
          {event.enableExhibitHall &&
            <>
              <SecuredRoute path={"/event/" + event.alias + "/exhibit-hall"}><ExhibitHall event={event} user={user} store={store} /></SecuredRoute>
              <SecuredRoute path={"/event/" + event.alias + "/exhibitor/:exhibitorId"}><Exhibitor event={event} user={user} store={store} /></SecuredRoute>
            </>
          }
          {(event?.supplementaryPages || []).map(page => <SecuredRoute key={page.id} path={"/event/" + event.alias + "/pages/" + page.alias} secured={!page.isPublic}><EventCustomPage data={page} /></SecuredRoute>)}
          {(event?.networkingRooms || []).map(room => <SecuredRoute key={room.id} path={"/event/" + event.alias + "/networking-room/" + room.id}><NetworkingRoom room={room} event={event} user={user} store={store} /></SecuredRoute>)}
        </React.Fragment>
      )}
      {(portal?.supplementaryPages || []).map(page => <SecuredRoute key={page.id} path={"/pages/" + page.alias} secured={!page.isPublic}><EventCustomPage data={page} /></SecuredRoute>)}
    </>
  )
}

export default inject(({ store }, props) => ({
  isLoggedIn: store.isLoggedIn, store, events: store.events, user: store.currentUser, portal: store.portal, products: store.products,
}))(Events);

