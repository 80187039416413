import React, { useState, useEffect, useMemo, useReducer, Fragment } from 'react';
import { inject } from 'mobx-react';
// import api from "../../api";
import usePortal from '../hooks/usePortal';
import RecordingDialog from "../dialogs/RecordingDialog";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as Scroll from 'react-scroll';
import { useTheme, /* makeStyles */ } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import uiConfig from "../../uiConfig";
import Filter from '../Filter';
import TracksGridList from '../TracksGridList';
import TracksList from '../TracksList';
import RecordingDetailsDialog from '../RecordingDetailsDialog';
import RecordingCard from '../RecordingCard';
import Hidden from '@material-ui/core/Hidden';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SignUpAndBuyDialog from '../dialogs/SignUpAndBuyDialog';
import ProductsGrid from '../ProductsGrid';
import HomePage from './HomePage';

const getUpdatedTracks = (tracks, recording) => {
  let newTracks = [...tracks];
  let tracksWithRecording = newTracks.filter(x => x.recordings?.some(c => c.id === recording.id));
  for (let track of tracksWithRecording) {
    let index = track.recordings.findIndex(x => x.id === recording.id);
    let newRecordings = [...track.recordings];
    newRecordings[index] = recording;
    track.recordings = newRecordings;
  }
  return newTracks;
}

const reducer = (state, newState) => ({ ...state, ...newState });

const getSpeakersString = rec => rec.speakers.map((speaker) => `${speaker.firstName} ${speaker.lastName}`).join(" ").toLowerCase();
const getSummaryString = rec => (rec.summary || "").toLowerCase();
const getNameString = rec => (rec.name || "").toLowerCase();
const checkRecordingForSearch = (rec, search) => getSpeakersString(rec).includes(search) || getSummaryString(rec).includes(search) || getNameString(rec).includes(search);

const TrackPage = ({ store, user, search, tracks, portal, recordings, products }) => {
  const [selectedTrackId, setSelectedTrackId] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [modalState, setModalState] = useReducer(reducer, { data: {} });
  const { urlPrefix, uiSettings } = usePortal();
  const { pathname } = useLocation();
  const { trackId, recordingId } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  // const recordings = useMemo(() => tracks.map(x => x.recordings || []).flat(), [tracks]);

  const searchResult = useMemo(() => {
    if (search.length < 3) {
      return tracks;
    }
    const searchString = search.toLowerCase();
    let result = tracks.map(x => ({ ...x, recordings: x.recordings.filter(r => checkRecordingForSearch(r, searchString)) }));
    result = result.filter(x => x.recordings.length > 0);
    return result;
  }, [tracks, search]);

  const handleFilterTracks = id => () => {
    if (id === trackId)
      return;
    if (id) {
      history.replace(urlPrefix + "/on-demand/" + id);
    } else
      history.replace(urlPrefix + "/on-demand");
  }

  const handleFilterTracksType = type => () => setSelectedType(type);

  const handleShowModal = (name, data = {}) => {
    setModalState({ [name]: !modalState[name], data });
  }

  const handleDetailsModalClose = () => setModalState({ isRecordingDetailsOpened: false, data: {} });

  const handleSelectProduct = product => {
    setModalState({ isRecordingBuyOpened: true, data: { type: "product", id: product.id } });
  }

  const handleBuyClose = (data) => {
    console.log("handleBuyClose", data);
    setModalState({ isRecordingBuyOpened: false, data: {} });
    if (recordingId)
      history.replace(pathname.replace("/" + recordingId, ""));
    if (!data) return;
    if (data === 'all') {
      // to get data
      if (user)
        store.setUser({ ...user });
      return;
    }
    const updateRecording = recording => {
      let newTracks = getUpdatedTracks(tracks, recording);
      store.setTracks(newTracks)
    }
    if (Array.isArray(data))
      data.forEach(x => updateRecording(x));
    else
      updateRecording(data);
  }


  useEffect(() => {
    if (tracks.length !== 1)
      return;
    if (trackId !== tracks[0].id)
      handleFilterTracks(tracks[0].id)();
  }, [tracks, trackId]);

  useEffect(() => {
    if (!selectedTrackId || uiSettings.signInRedirect.includes(selectedTrackId))
      return;
    Scroll.scroller.scrollTo('tracks-start', {
      smooth: true,
      offset: uiConfig.trackPageScrollOffset,
    });
  }, [searchResult, selectedTrackId, selectedType]);

  useEffect(() => {
    setSelectedTrackId(trackId || "");
  }, [trackId]);

  useEffect(() => {
    if (!portal)
      return;
    const recording = recordings.find(x => x.id === recordingId);
    if (recording) {
      const { alias, questionsCount } = recording;
      const link = (questionsCount > 0 && alias) ? urlPrefix + `/quizzes/${recording.id}` : null;
      const price = portal.canBuySingleRecording ? (user?.isMember ? recording.priceForMembers : recording.price) : null;
      const relatedProducts = portal.products.filter(p => (p.recordings || []).includes(recording.id));
      console.log("relatedProducts", [...relatedProducts.map(x => ({ ...x }))], price, { ...recording });
      if (link)
        history.replace(link);
      else if (alias)
        setModalState({ isRecordingOpened: true, data: recording });
      else {
        if (price !== null || relatedProducts.some(x => x.isAvailable))
          setModalState({ isRecordingBuyOpened: true, data: recording });
        // else
        //   history.push(pathname + "/sign-up");
      }
    } else
      setModalState({ isRecordingOpened: false, isRecordingBuyOpened: false, data: {} });
  }, [recordingId, recordings, portal]);

  const showFilter = tracks.length > 1;
  const hideTracks = selectedTrackId || search.length > 2;
  let renderedRecordingsCount = 0;
  const showProducts = uiConfig.showProducts && products.filter(x => x.isAvailable).length > 0;
  return (
    <>
      {uiSettings.home.showInOnDemand && <HomePage />}
      <br />
      <Grid container spacing={2}>
        {showFilter &&
          <Hidden smUp>
            <Grid item sm={6} xs={12}>
              <Filter
                title="Topics"
                handleItemClick={handleFilterTracks}
                items={tracks}
                selected={selectedTrackId}
                defaultItem={isSm ? null : { id: 0, title: "All Tracks" }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              {uiConfig.CEfilter &&
                <Filter
                  title="Types"
                  handleItemClick={handleFilterTracksType}
                  items={[{ id: 1, title: uiConfig.certTitle + " Only" }, { id: 2, title: "Non-" + uiConfig.certTitle }]}
                  selected={selectedType}
                  defaultItem={{ id: 0, title: "All Types" }}
                />
              }
            </Grid>
          </Hidden>
        }
        <Grid item md={showFilter ? 10 : 12} sm={12}>
          <Scroll.Element name="tracks-start" />
          {showProducts &&
            // <Container>
            <div>
              <br />
              <Scroll.Element name="tracks-start" />
              <Typography variant="h6" gutterBottom>Special Offer</Typography>
              <ProductsGrid products={products} onSelect={handleSelectProduct} />
            </div>
            // </Container>
          }
          {hideTracks ?
            searchResult.map((track, index) => {
              if (selectedTrackId && selectedTrackId !== track.id)
                return null;
              const filteredRecordings = track.recordings.filter(x => !selectedType || (selectedType === 1 && x.ceHours) || (selectedType === 2 && !x.ceHours)).
                sort((a, b) => uiConfig.enableFreeProducts ? !!b.alias - !!a.alias : 1);
              if (filteredRecordings.length === 0 && !selectedTrackId)
                return null;
              return (
                <Fragment key={index}>
                  <br />
                  {showFilter && <Typography variant="h6" gutterBottom>{track.title}</Typography>}
                  <Grid container spacing={3} justifyContent="flex-start">
                    {filteredRecordings.map((recording) => {
                      renderedRecordingsCount++;
                      return (
                        <Grid item xs={12} sm={6} md={4} key={recording.id}>
                          <RecordingCard recording={recording} openModal={handleShowModal} trackId={selectedTrackId ? null : track.id} />
                        </Grid>
                      )
                    })}
                  </Grid>
                </Fragment>
              )
            }
            ) :
            <Hidden xsDown>
              {uiSettings.trackImages ?
                <TracksGridList tracks={tracks} handleClick={handleFilterTracks} /> :
                <TracksList tracks={tracks} handleClick={handleFilterTracks} />
              }
            </Hidden>
          }
          {(!renderedRecordingsCount && tracks.length > 0 && search.length > 2) &&
            <>
              <br />
              <Typography>Sorry, we could not find any {selectedType ? (selectedType === 1 ? uiConfig.certTitle + " " : `non-${uiConfig.certTitle} `) : ""}recordings matching &quot;<b>{search}</b>&quot;.</Typography>
            </>
          }
        </Grid>
        {showFilter &&
          <Hidden smDown>
            <Grid item md={2} sm={12}>
              <div style={{ position: 'sticky', top: uiConfig.main.childrenMarginTop + 20 }}>
                <Filter
                  title="Topics"
                  handleItemClick={handleFilterTracks}
                  items={tracks}
                  selected={selectedTrackId}
                  defaultItem={{ id: 0, title: "All Topics" }}
                  style={{ maxHeight: `calc(100vh - ${uiConfig.main.childrenMarginTop + 20}px - ${uiConfig.CEfilter ? 180 : 0}px)`, overflowY: "auto" }}
                />
                {uiConfig.CEfilter &&
                  <Filter
                    title="Types"
                    handleItemClick={handleFilterTracksType}
                    items={[{ id: 1, title: uiConfig.certTitle + " Only" }, { id: 2, title: "Non-" + uiConfig.certTitle }]}
                    selected={selectedType}
                    defaultItem={{ id: 0, title: "All Types" }}
                  />
                }
              </div>
            </Grid>
          </Hidden>
        }
      </Grid>
      {modalState.isRecordingOpened && <RecordingDialog video={modalState.data} user={user} hide={() => handleShowModal("isRecordingOpened")} />}
      {modalState.isRecordingBuyOpened && <SignUpAndBuyDialog recording={modalState.data} handleClose={handleBuyClose} />}
      <RecordingDetailsDialog show={!!modalState.isRecordingDetailsOpened} recording={modalState.data} handleClose={handleDetailsModalClose} />
      <br />
    </>
  );
}

export default inject(({ store }, props) => ({
  store,
  user: store.currentUser,
  portal: store.portal,
  search: store.search,
  tracks: store.tracks,
  products: store.products,
  recordings: store.recordings
}))(TrackPage);
