import React from 'react';

import { makeStyles, alpha, useTheme } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles(theme => ({
  headerTile: {
    height: 'auto !important'
  },
  tile: {
    cursor: 'pointer',
    "& img": {
      // padding: '10%',
      // width: '80%',
      transform: 'scale(0.65)',
      transition: 'transform .5s ease',
      left: 0,
      top: 0,
      maxWidth: "100%",
    },
    '&:hover': {
      '& img': {
        transform: 'scale(0.8)',
      }
    }
  },
  bar: {
    backgroundColor: alpha(theme.palette.primary.main, .7),
  },
  barTitle: {
    fontSize: '0.92rem'
  }
}));

const TracksGridList = ({ tracks, handleClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <ImageList rowHeight={'auto'} cols={isSm ? 3 : 4}>
      {/*<GridListTile key="Subheader" cols={cols} className={classes.headerTile}>
        <ListSubheader component="div">Tracks</ListSubheader>
      </GridListTile>*/}
      {tracks.map((track, index) => (
        <ImageListItem key={track.id} onClick={handleClick(track.id)} className={classes.tile}>  
          <img src={track.imageUrl} alt={track.title} />
          <ImageListItemBar
            title={track.title}
            className={classes.bar}
            classes={{ title: classes.barTitle }}
            //subtitle={<span>by: {track.author}</span>}
          />
        </ImageListItem>
      ))}
    </ImageList> 
  );
}

export default TracksGridList;
