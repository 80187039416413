import React from 'react';
import { CardElement } from 'react-stripe-elements';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  stripe: {
    border: '1px solid ' + theme.palette.text.secondary,
    padding: 10,
    borderRadius: 4,
    fontSize: '1rem',
  },
}));

const CardComponent = () => {
  const classes = useStyles();
  return(
    <CardElement style={{ base: { fontSize: 14 } }} className={classes.stripe} hidePostalCode />
  );
}

export default CardComponent;