import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import Autolinker from 'autolinker';
import useOnScreen from "../hooks/isOnScreenHook";
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'inline-block'
  },
  message: {
    margin: theme.spacing(1, 2),
    padding: theme.spacing(1, 1.5),
    width: '80%',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    position: 'relative',
  },
  messageLeft: {
    borderBottomLeftRadius: 0,
    float: "left",
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 'calc(100% - 2px)',
      width: 0,
      height: 0,
      border: theme.spacing(1.5) + 'px solid transparent',
      borderRightColor: theme.palette.primary.main,
      borderLeft: 0,
      borderBottom: 0,
      marginTop: -10,
      marginLeft: -theme.spacing(1.5),
    },
  },
  messageRight: {
    borderBottomRightRadius: 0,
    float: "right",
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 'calc(100% - 2px)',
      width: 0,
      height: 0,
      border: theme.spacing(1.5) + 'px solid transparent',
      borderLeftColor: theme.palette.primary.main,
      borderRight: 0,
      borderBottom: 0,
      marginTop: -10,
      marginRight: -theme.spacing(1.5),
    },
  },
  header: {
    "& b": {

    }
  },
  time: {
    float: 'right',
    color: theme.palette.grey[400],
  },
  systemMessage: {
    margin: theme.spacing(1, 2),
    textAlign: 'center',
    width: '100%',
  },
  text: {
    marginTop: theme.spacing(.5),
    "& > a": {
      wordBreak: 'break-all',
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    }
  },
  moreButton: {
    color: theme.palette.primary.contrastText,
    padding: 0,
    marginTop: -3,
    marginLeft: theme.spacing(0.5),
    float: 'right'
  },
  attachmentImg: {
    maxWidth: '100%',
  },
  role: {
    marginLeft: theme.spacing(1),
    top: -0.5,
    position: "relative"
  }
}));

const Attachment = ({ data: { name, url } }) => {
  const classes = useStyles();
  if (name.endsWith(".png") || name.endsWith(".jpg"))
    return (<a href={url} target="_blank" rel="noopener noreferrer"><img className={classes.attachmentImg} src={url} alt={name} /></a>)

  return (<><span>File: </span><a href={url} target="_blank" rel="noopener noreferrer">{name}</a></>)
}

const Message = ({ data, handleMenuOpen, connection, oneWay }) => {
  const [roleLabel, setRoleLabel] = useState("");

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (!connection.roomId)
      return;
    const getLabel = async () => {
      const label = await connection.getUserRoleLabel(data.sender_id);
      setRoleLabel(label);
    }
    getLabel();
  }, [data.sender_id]);

  useEffect(() => {
    if (!isVisible)
      return;
    if (!data || !connection?.profile)
      return;
    if (data.sender_id === connection.profile.user_id)
      return;
    if (data.flags?.includes("read"))
      return;
    const addflag = {
      messages: [data.id],
      flag: "read",
    };
    connection.client.messages.flags.add(addflag);
  }, [data, connection, isVisible]);

  const attachment = useMemo(() => {
    if (!data.content.startsWith("::FILE"))
      return null;
    const json = data.content.replace("::FILE", "");
    const file = JSON.parse(json);
    return file;
  }, [data.content]);

  const text = useMemo(() => {
    return Autolinker.link(data.content);
  }, [data.content]);

  const { sender_full_name, sender_id, timestamp: sentAt } = data;
  const classes = useStyles();

  let name = sender_full_name;
  const time = dayjs(sentAt * 1000).format('LT');
  // let { content: text } = data;
  // if (type === 'message' || type === 'groupMember') {
  //   text = data.message;
  //   name = '';
  // }
  const showMore = connection.isAdmin; // && (type === 'text' || type === 'file');
  const showBan = showMore && sender_id !== connection.profile.user_id && !roleLabel;
  const showDelete = showMore;

  const handleMoreClick = ({ currentTarget }) => handleMenuOpen({ anchorEl: currentTarget, data, showDelete, showBan });

  const floatRight = sender_id === connection.profile.user_id;
  if (oneWay && !roleLabel && sender_id !== connection.profile.user_id)
    return null;
  if (!name) {
    return (
      <div className={classes.systemMessage}>
        <Typography variant="caption" color="textSecondary">{time}</Typography>
        <Typography variant="body2" gutterBottom>{text}</Typography>
      </div>
    );
  }
  return (
    <div className={classes.root} ref={ref}>
      <div className={classes.message + ' ' + (floatRight ? classes.messageRight : classes.messageLeft)}>
        <Typography className={classes.header} component="div">
          {name && <b>{name}</b>}
          {roleLabel && <Typography variant="body2" color="error" component="span" className={classes.role}>{roleLabel}</Typography>}
          {(showDelete || showBan) && <IconButton className={classes.moreButton} onClick={handleMoreClick}><MoreIcon /></IconButton>}
          <Typography variant="caption" className={classes.time}>{time}</Typography>
        </Typography>
        <Typography component="div" className={classes.text} dangerouslySetInnerHTML={attachment ? undefined : { __html: text }}>
          {attachment &&
            <Attachment data={attachment} />
          }
        </Typography>
      </div>
    </div>
  );
}

Message.propTypes = {
  data: PropTypes.object.isRequired,
  // isAdmin: PropTypes.bool,
  // userId: PropTypes.number.isRequired,
};

export default Message;