import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { useParams } from 'react-router-dom';
import { inject } from "mobx-react";

import Typography from '@material-ui/core/Typography';
import api from "../../api";
import QuizMenu from "./../../components/quiz/QuizMenu";
import QuizCourseReview from "./../../components/quiz/QuizCourseReview";
import QuizQuestions from "./../../components/quiz/QuizQuestions";
import QuizVideoEmbed from "./../../components/quiz/QuizVideoEmbed";
import QuizCertificate from "./../../components/quiz/QuizCertificate";
import QuizFailure from "./../../components/quiz/QuizFailure";
import QuizSuccess from "./../../components/quiz/QuizSuccess";
import usePortal from "../hooks/usePortal";
import { useFeedback } from "../feedback/Service";

class QuizPage extends PureComponent {
  constructor(...args) {
    super(...args);
    // TODO: use mobX store
    this.state = {
      step: 1,
      recording: null,
      question: null,
      isSubmitLocked: false,
      answerExplanation: null,
      isFfetching: false,
    };
  }

  componentDidMount() {
    this.refetchState();
  }

  componentWillUnmount() {
    this.props.store.setIsFetching(false, "quiz");
  }

  refetchState = async () => {
    const { params, store } = this.props;
    store.setIsFetching(true, "quiz");
    try {
      const data = await api.getRecordingQuiz(params.id, this.props.portal.portalName);
      let nextStep = data.step;

      if (nextStep === 2 && !data.question)
        nextStep = 3;

      if (nextStep === 4 && !data.question)
        nextStep = 6;

      this.setState({
        attemptsLeft: data.attemptsLeft,
        step: nextStep,
        recording: data.recording,
        question: data.question,
        actualScore: data.actualScore,
        passingScore: data.passingScore,
        answerExplanation: null,
        currentQuestion: data.currentQuestion,
        totalQuestions: data.totalQuestions,
      });
    } catch (error) {
      console.log("getRecordingQuiz", "error", error);
      this.props.feedback.snackbar({ text: error.response?.data?.message || "Unknown error", type: "error" });
    }
    this.setState({ isSubmitLocked: false });
    store.setIsFetching(false, "quiz");
  }

  handleProceedAfterExplanation = () => {
    this.setState({ isSubmitLocked: true });
    this.refetchState();
  }

  handleCourseReviewDone = () => {
    this.setState({ isSubmitLocked: true });
    api.setRecordingQuizStep(this.state.recording.id, this.props.portal.portalName).then(this.refetchState, () => {
      this.setState({ isSubmitLocked: false })
    });
  }

  handleFeedback = feedback => {
    this.setState({ isSubmitLocked: true });
    api.setRecordingQuizFeedback(this.state.recording.id, feedback, this.props.portal.portalName).then(this.refetchState, () => {
      this.setState({ isSubmitLocked: false })
    });
  }

  handleQuestionSubmit = answerId => {
    this.setState({ isSubmitLocked: true });
    api.postRecordingQuizAnswer(this.state.recording.id, answerId, this.props.portal.portalName)
      .then(resp => {
        if (resp.success && resp.explanation) {
          this.setState({ answerExplanation: resp.explanation, isSubmitLocked: false })
        } else {
          this.setState({ answerExplanation: null })
          this.refetchState();
        }
      }, (jqResp) => {
        const errorText = `Http Status: ${jqResp.status}. Response: ${jqResp.responseText}`,
          base64Encoded = window.btoa(errorText);
        window.prompt("Unexpected error occured. Please contact support and provide infromation below.", base64Encoded);
        this.setState({ isSubmitLocked: false });
      });
  }

  handleVideoDone = () => {
    this.setState({ isSubmitLocked: true });
    if (this.state.attemptsLeft === 0) {
      this.props.feedback.snackbar({ text: "No attempts left" });
      return;
    }
    api.setRecordingQuizStep(this.state.recording.id, this.props.portal.portalName).then(this.refetchState, () => {
      this.setState({ isSubmitLocked: false })
    });
  }

  handleRetryClick = async () => {
    this.setState({ isSubmitLocked: true });
    try {
      await api.setQuizReset(this.state.recording.id, this.props.portal.portalName);
      await this.refetchState();
    } catch (error) {
      console.log("setQuizReset", "error", error);
      this.props.feedback.snackbar({ text: error.response?.data?.message || "Unknown error", type: "error" });
    }
    this.setState({ isSubmitLocked: false })
  }

  handleGetCMEClick = () => {
    this.setState({ step: 7 });
  }

  render() {
    if (!this.state.recording)
      return (
        <div className="quiz-page">
          <div className="text-center">
            <div className="loader loader-sm"></div>
          </div>
        </div>
      );

    const portalLink = this.props.portal.urlPrefix + "/on-demand",
      trackLink = `${portalLink}`,
      accountLink = `${portalLink}/account`;
    const { name } = this.state.recording;
    return (
      <>
        <br />
        <br />
        <Typography variant="h5">{name}</Typography>
        <QuizMenu step={this.state.step} />
        {this.state.step === 1 &&
          <QuizCourseReview recording={this.state.recording} onNextClick={this.handleCourseReviewDone} isNextLocked={this.state.isSubmitLocked} />
        }
        {(this.state.step === 2 || this.state.step === 4) &&
          <QuizQuestions
            answerExplanation={this.state.answerExplanation}
            question={this.state.question}
            onSubmit={this.state.answerExplanation ? this.handleProceedAfterExplanation : this.handleQuestionSubmit}
            isSubmitLocked={this.state.isSubmitLocked}
            onRetryClick={this.handleRetryClick}
            currentQuestion={this.state.currentQuestion}
            totalQuestions={this.state.totalQuestions}
          />
        }
        {this.state.step === 3 &&
          <QuizVideoEmbed portalName={this.props.name} recording={this.state.recording} onNextClick={this.handleVideoDone} isNextLocked={this.state.isSubmitLocked} onRetryClick={this.handleRetryClick} attemptsLeft={this.state.attemptsLeft} />
        }
        {this.state.step === 5 &&
          <QuizFailure passingScore={this.state.passingScore} userScore={this.state.actualScore} onRetryClick={this.handleRetryClick} isRetryLocked={this.state.isSubmitLocked} goBackLink={trackLink} attemptsLeft={this.state.attemptsLeft} />
        }
        {this.state.step === 6 &&
          <QuizSuccess passingScore={this.state.passingScore} userScore={this.state.actualScore} goBackLink={trackLink} onGetCMEClick={this.handleGetCMEClick} />
        }
        {this.state.step === 7 &&
          <QuizCertificate email={this.props.user.email} goBackLink={trackLink} accountLink={accountLink} recording={this.state.recording} />
        }
        <br />
        <br />
      </>
    );
  }
}

QuizPage.propTypes = {
  params: PropTypes.object.isRequired
};

export default inject(({ store }, props) => ({
  user: store.currentUser, store
}))((props) => {
  const params = useParams();
  const portal = usePortal();
  const feedback = useFeedback();
  return <QuizPage {...props} params={params} portal={portal} feedback={feedback} />
});
