import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { inject } from "mobx-react";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useAmplitude } from "react-amplitude-hooks";

import api from "../../api";
import { useFeedback } from "../feedback/Service";
import CloseButton from "../CloseButton";
import LoadingButton from "../LoadingButton";
import SupportLink from "../SupportLink";

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginBottom: theme.spacing(1),
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

const getHashParams = () => {
  const url = new URL(window.location.href.replace(/#/g, "?"));
  const token = url.searchParams.get("token");
  const email = url.searchParams.get("email");
  return { code: token, email };
}

const ResetPasswordDialog = ({ store, portal }) => {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { code, email } = getHashParams();

  // const { logEvent } = useAmplitude({ page: "login" });
  const feedback = useFeedback();
  const history = useHistory();
  const classes = useStyles();
  // const redirectUrl = urlPrefix || "/";
  const redirectUrl = "/login";
  const backUrl = "/"

  const handleClose = () => history.push(backUrl);

  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      await api.resetPassword(email, password, code);
      feedback.snackbar({ text: "Password set", type: "success" });
      setLoading(false);
      history.push(redirectUrl);
      return;
    } catch (error) {
      console.warn("Reset password error", error);
      feedback.snackbar({ text: error.response?.data?.message || error.response?.data?.error_description || "Unknown error", type: "error" });
    }
    setLoading(false);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open onClose={handleClose} aria-labelledby="signin-dialog-title" fullWidth fullScreen={fullScreen}>
      {fullScreen && <CloseButton onClick={handleClose} />}
      <DialogTitle id="signin-dialog-title">Reset Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {email}<br />
        </DialogContentText>
        <form onSubmit={handleSubmit} id="signin-form" className={classes.form}>
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
          <TextField
            variant="outlined"
            margin="dense"
            required
            fullWidth
            name="repeat-password"
            label="Repeat password"
            type="password"
            onChange={e => setRepeatPassword(e.target.value)}
            value={repeatPassword}
            error={password !== repeatPassword}
            helperText={password === repeatPassword ? undefined : "Passwords must match"}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            loading={loading}
          >
            Submit
          </LoadingButton>
        </form>
      </DialogContent>
      <DialogActions>
        <SupportLink />
        <div style={{ width: '100%' }} />
      </DialogActions>
    </Dialog>
  );
}

ResetPasswordDialog.propTypes = {
  store: PropTypes.object.isRequired
};


export default inject(({ store }, props) => ({
  portal: store.portal, store
}))(ResetPasswordDialog);
