import React from 'react';
import { makeStyles/* , useTheme */ } from '@material-ui/core/styles';
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  image: {
    // padding: '10%',
    // width: '80%',    
    // maxHeight: 200,
  },
  card: {
    width: '100%',
    minHeight: 210,
    display: "flex",
    flexDirection: "column",
    "& > .MuiCardActionArea-root": {
      flexGrow: 1
    }
  },
  bar: {
    height: 40,
  },
  tile: {
    // cursor: 'pointer',
    "& img": {
      // padding: '10%',
      // width: '80%',
      // transform: 'scale(1) translateX(-50%)',
      transition: 'transform .5s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'scale(1.06) translateX(-47%)',
      }
    }
  },
  description: {
    whiteSpace: 'pre-wrap'
  },
}));

const ProductCard = ({ product, handleSelect }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBuy = () => {
    if (handleSelect) {
      handleSelect(product);
      return;
    }
    history.push("/buy/" + product.id);
  }

  const showImage = product.imageUrl && !product.imageUrl.includes("image.png"); 
  return (
    <Card className={classes.card}>
      <CardActionArea onClick={handleBuy} disabled={!product.isAvailable}>
        {showImage &&
          <CardMedia
            component="img"
            alt={product.title}
            // height={140}
            image={product.imageUrl}
            title={product.title}
          />
        }
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {product.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
            {product.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={handleBuy} disabled={!product.isAvailable}>
          {product.isAvailable ? "Buy" : (product.isPurchased ? "Purchased" : "Not Available")}
        </Button>
        {/* <Button size="small" color="primary">
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  );
}

const ProductsGrid = ({ products, xs = 12, sm = 6, md = 4, lg = 3, onSelect }) => {
  // const { urlPrefix } = usePortal();
  // const classes = useStyles();
  // const theme = useTheme();
  // const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Grid container spacing={2}>
        {products.map(product => (
          <Grid key={product.id} item xs={xs} sm={sm} md={md} lg={lg}>
            <ProductCard product={product} handleSelect={onSelect} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ProductsGrid;
