import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import api from "../../api";
import Button from "@material-ui/core/Button";
import { useFeedback } from "../feedback/Service";
import { inject } from "mobx-react";
// import { useHistory } from "react-router-dom";
import DataTable from "../DataTable";
import Typography from "@material-ui/core/Typography";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import uiConfig from '../../uiConfig';
import Users from "../admin/Users";
import Members from "../admin/Members";
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

const loginCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "date", title: "Date" },
];

const salesCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "description", title: "Description" },
  { name: "amount", title: "Amount" },
  { name: "amountRefunded", title: "Amount Refunded" },
  { name: "date", title: "Date" },
];

const certificatesCols = [
  { name: "email", title: "Email" },
  { name: "firstName", title: "First Name" },
  { name: "lastName", title: "Last Name" },
  { name: "sessionName", title: "Session" },
  { name: "date", title: "Date" },
  { name: "score", title: "Score" },
];

const Admin = ({ store, isFetching, user, events, portal }) => {
  const [logins, setLogins] = useState([]);
  const [sales, setSales] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [salesTab, setSalesTab] = useState(0);
  const [isFetchingSales, setIsFetchingSales] = useState(false);
  const [isFetchingCerts, setIsFetchingCerts] = useState(false);
  const [isFetchingConnectionTrackerData, setIsFetchingConnectionTrackerData] = useState(false);
  const [isFetchingPlaybackTrackerData, setIsFetchingPlaybackTrackerData] = useState(false);
  const [isFetchingSessionEvaluationData, setIsFetchingSessionEvaluationData] = useState(false);
  const [isFetchingSignUpData, setIsFetchingSignUpData] = useState(false);

  // const history = useHistory();

  const feedback = useFeedback();

  const downloadFile = async (func, name) => {
    try {
      const res = await func();
      console.log(res);
      // const attachement = res.headers["content-disposition"];
      const url = URL.createObjectURL(res);
      const linkEl = document.createElement("a");
      linkEl.href = url;
      linkEl.download = name + "." + res.type.split("/").pop();
      linkEl.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100, url);
    } catch (error) {
      feedback.snackbar({ text: error.message, type: "error" });
    }
  }

  const handleGetLoginsClick = () => {
    downloadFile(api.getLoginsCsv.bind(api), "logins");
  }

  const handleGetSignUpsClick = (id, name) => async () => {
    setIsFetchingSignUpData(true);
    await downloadFile(api.getSignUpsCsv.bind(api, id), name + " signups");
    setIsFetchingSignUpData(false);
  }

  const handleGetConnectionTrackerDataClick = (eventId, name) => async () => {
    setIsFetchingConnectionTrackerData(true);
    await downloadFile(api.getEventConnectionTrackerDataCsv.bind(api, eventId), name + " connection_tracker_data");
    setIsFetchingConnectionTrackerData(false);
  }

  const handleGetAttentionTrackerDataClick = (eventId, name) => async () => {
    setIsFetchingConnectionTrackerData(true);
    await downloadFile(api.getEventAttentionTrackerDataCsv.bind(api, eventId), name + " attention_tracker_data");
    setIsFetchingConnectionTrackerData(false);
  }

  const handleGetPlaybackTrackerDataClick = async () => {
    setIsFetchingPlaybackTrackerData(true);
    await downloadFile(api.getPlaybackTrackerDataCsv.bind(api), "playback_tracker_data");
    setIsFetchingPlaybackTrackerData(false);
  }

  const handleGetSessionEvaluationDataClick = (id, name) => async () => {
    setIsFetchingSessionEvaluationData(true);
    await downloadFile(api.getSessionEvaluationDataCsv.bind(api, id), name + " session_evaluation_data");
    setIsFetchingSessionEvaluationData(false);
  }

  const handleGetSalesClick = () => {
    downloadFile(api.getSalesCsv.bind(api), "sales");
  }

  const handleGetCertificatesClick = () => {
    downloadFile(api.getLastCertificatesCsv.bind(api), "certificates");
  }

  useEffect(() => {
    const getSales = async days => {
      setSales([]);
      setIsFetchingSales(true);
      const sales = await api.getSales(days);
      setSales(sales.map(x => ({ ...x, date: dayjs(x.created).format("lll") + " UTC" })));
      setIsFetchingSales(false);
    }
    const days = salesTab === 0 ? 1 : (salesTab === 1 ? 7 : 30);
    getSales(days);
  }, [salesTab]);

  useEffect(() => {
    const getLogins = async () => {
      const logins = await api.getLogins();
      setLogins(logins.map(x => ({ ...x, date: dayjs(x.dateTime).format("lll") + " UTC" })));
    }
    const getSales = async () => {
      setIsFetchingSales(true);
      const sales = await api.getSales(1);
      // console.log("sales", sales);
      setSales(sales.map(x => ({ ...x, date: dayjs(x.created).format("lll") + " UTC" })));
      setIsFetchingSales(false);
    }
    const getCerts = async () => {
      setIsFetchingCerts(true);
      const certificates = await api.getLastCertificates();
      // console.log("certificates", certificates);
      setCertificates(certificates.map(x => ({ ...x, date: dayjs(x.issued).format("lll") + " UTC" })));
      setIsFetchingCerts(false);
    }
    getLogins();
    getSales();
    getCerts();
  }, []);

  // console.log("events", events?.length);

  return (
    <>
      <br />
      <Users />
      {uiConfig.showMembers && <Members />}
      {(events.some(x => x.signUpFormMetaData) || portal.signUpFormMetaData) &&
        <>
          <Typography variant="h6" gutterBottom>Sign Up Data</Typography>
          {isFetchingSignUpData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {portal.signUpFormMetaData &&
                <ListItem button onClick={handleGetSignUpsClick(portal.id, "Portal")}>
                  <ListItemText primary={"Portal - Download CSV"} />
                </ListItem>
              }
              {events.filter(x => x.signUpFormMetaData).map(event => (
                <ListItem key={event.id} button onClick={handleGetSignUpsClick(event.id, event.name)}>
                  <ListItemText primary={event.name + " - Download CSV"} />
                </ListItem>
              ))}
            </List>
          }
          <br />
        </>
      }
      {(portal.events.some(x => ["connection", "attention"].includes(x.tracking)) || user.isSuperAdmin) &&
        <>
          <Typography variant="h6" gutterBottom>Events Trackers Reports Download</Typography>
          {isFetchingConnectionTrackerData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {events.map(event => (
                <ListItem key={event.id}>
                  <ListItemText primary={event.name} />
                  {event.tracking === "attention" ?
                    <Button variant="outlined" onClick={handleGetAttentionTrackerDataClick(event.id, event.name)} style={{ marginLeft: 8 }}>Attention Tracker</Button> :
                    <Button variant="outlined" onClick={handleGetConnectionTrackerDataClick(event.id, event.name)} style={{ marginLeft: 8 }}>Connection Tracker</Button>
                  }
                  {/* <Button variant="outlined" onClick={handleGetPlaybackTrackerDataClick(event.id)} style={{ marginLeft: 8 }}>Playback Tracker</Button> */}
                </ListItem>
              ))}
            </List>
          }
        </>
      }
      {(uiConfig.enablePlaybackTracking || user.isSuperAdmin) &&
        <>
          <br />
          <Typography variant="h6" gutterBottom>Playback Tracker Report Dowload</Typography>
          {isFetchingPlaybackTrackerData ?
            <LinearProgress variant="query" /> :
            <Button onClick={handleGetPlaybackTrackerDataClick} variant="contained" >Download CSV</Button>
          }
          <br />
        </>
      }
      <br />
      {(events.some(x => x.evaluationFormMetaData) || portal.evaluationFormMetaData) &&
        <>
          <Typography variant="h6" gutterBottom>Session Evaluation Data</Typography>
          {isFetchingSessionEvaluationData ?
            <LinearProgress variant="query" /> :
            <List component="nav" aria-labelledby="events-list-subheader">
              {portal.evaluationFormMetaData &&
                <ListItem button onClick={handleGetSessionEvaluationDataClick(portal.id, "Portal")}>
                  <ListItemText primary={"Portal - Download CSV"} />
                </ListItem>
              }
              {events.filter(x => x.evaluationFormMetaData).map(event => (
                <ListItem key={event.id} button onClick={handleGetSessionEvaluationDataClick(event.id, event.name)}>
                  <ListItemText primary={event.name + " - Download CSV"} />
                </ListItem>
              ))}
            </List>
          }
        </>
      }
      <br />
      <Typography variant="h6" gutterBottom>Last 10 logins</Typography>
      <Button onClick={handleGetLoginsClick} variant="contained" >Download CSV</Button>
      <br />
      <br />
      <DataTable cols={loginCols} rows={logins} />
      <br />
      <br />
      <Typography variant="h6" gutterBottom>Last sales</Typography>
      <Button onClick={handleGetSalesClick} variant="contained" >Download CSV</Button>
      <br />
      <br />
      <Tabs
        value={salesTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, v) => setSalesTab(v)}
      >
        <Tab label="Today" />
        <Tab label="Last 7 days" />
        <Tab label="Last 30 days" />
      </Tabs>
      <DataTable cols={salesCols} rows={sales} />
      {isFetchingSales && <LinearProgress variant="query" />}
      {portal?.hasCertification &&
        <>
          <br />
          <br />
          <Typography variant="h6" gutterBottom>Last 10 certificates</Typography>
          <Button onClick={handleGetCertificatesClick} variant="contained" >Download CSV</Button>
          <br />
          <br />
          <DataTable cols={certificatesCols} rows={certificates} />
          {isFetchingCerts && <LinearProgress variant="query" />}
        </>
      }
    </>
  );
};

Admin.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
}

export default inject(({ store }, props) => ({
  isFetching: store.isFetching, store, user: store.currentUser, events: store.events, portal: store.portal,
}))(Admin);
