function wrapError(e, caller) {
  if (null === e) { return null; }
  var ret = ((typeof e) === (typeof "")) ? new Error(e) : new Error(e.message);
  ret.stackTrace = e.stackTrace || [];
  ret.stackTrace.push(caller);
  return ret;
}

function wrapMethod(fn, name) {
  return async function () { /* Wrapper added by safeWrapMethod */
    try {
      return fn.apply(this, arguments).then(data => {
        if (data.result === "error") {
          throw new Error(data.msg)
        }
        return data;
      });
    }
    catch (e) {
      throw wrapError(e, name);
    }
  };
}

export function wrapObject(object, name) {
  for (let m in object) {
    if (typeof (object[m]) === "function") {
      object[m] = wrapMethod(object[m], name + "." + m);
    }
    if (typeof (object[m]) === "object") {
      wrapObject(object[m], name);
    }
  }
}