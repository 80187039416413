import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import config from '../uiConfig';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  support: {
    minWidth: 210,
    marginLeft: 15,
  },
}));

const SupportLink = () => {
  const classes = useStyles();
  return (
    <Typography className={classes.support} variant="body2" gutterBottom>
      Support: <Link href={`mailto:${config.supportEmail}`}>{config.supportEmail}</Link>
    </Typography>
  );
}

export default SupportLink;